import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import ReactMultiSelectCheckboxes from '../common/ReactMultiSelectCheckboxes';
import MasterService from '../../services/MasterService';
import { defaultZone, Zone, VIRTUAL_ADMIN, Business, defaultBusiness, Vertical, defaultVertical, VP_ID, Role, validationForFields, ROLE_ABOVE_BM, ADMIN_ROLES, TENANT, ECA_AGENT_TAGGING } from '../../config/constant';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import { useHasRight } from '../hooks/useHasRights'

const { ValueContainer, Placeholder } = components;




const RoleDetails = (props) => {
    const { stateList, user_detail, updateBasicDetail, updateEcaAgent } = props;
    const [roleList, setRoleList] = useState([])
    const [CityData, setCityData] = useState(null);
    const [StateData, setStateData] = useState(null);
    const [reportingRoleList, setReportingRoleList] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    const [secondaryReportingRoleList, setSecondaryReportingRoleList] = useState([])
    const [secondaryReportingManagerList, setSecondaryReportingManagerList] = useState([])
    const [role_details, setRoleDetails] = useState({ zone_id: defaultZone, })
    const [role_change, setRoleChange] = useState(false)
    const [errors, SetErrors] = useState(props.location_state && props.location_state.tempErrors ? props.location_state.tempErrors : {});
    const [cityList, setCityList] = useState([])
    const [loading, setLoading] = useState(false);
    const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const historyLead = useNavigate();
    const [BusinessLine, SetBusinessLine] = useState([]);
    const { adminRoleId } = useHasRight();
    const [reporteeRoleChange, setReporteeRoleChange] = useState(false)
    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        let text = placeholderButtonLabel.split("*")[0]
        if ((stateList.length > 0 || cityList.length > 0) && value && value.some((o) => o.value === "*")) {
            return `All ${text} selected`;
        } else if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            if (text === "State" && value?.length === stateList.length) {
                return `All ${text} selected`;
            } else if (text === "City" && value?.length === cityList.length) {
                return `All ${text} selected`;
            } else {
                return `${value.length} ${text} selected`;
            }
        } else {
            return `${placeholderButtonLabel}`;
        }
    }

    useEffect(() => {
        getRoleList()
        if (user_detail.role_details) {
            let role_details = user_detail.role_details
            role_details.zone_id = defaultZone
            setRoleDetails(role_details)
            getCityAndStateData(role_details, true)
            getBusinessLineList(role_details.role)
            getReportingRole(role_details)
            getUsersByRole(role_details.reporting_role, "reporting_role", role_details.business_line)
            getUsersByRole(role_details.secondary_reporting_role, "secondary_reporting_role", role_details.business_line)
            if (user_detail.user_id) {
                setEdit(true)
            }
        } else {
            getBusinessLineList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_detail])

    useEffect(()=>{
        if(role_details.role && role_details.business_line){
            getReportingRole(role_details)
        }
    }, [role_details.role, role_details.business_line])

    const getBusinessLineList = async (role_id) => {
        MasterService.post('/sfa/user/business_line_list', {})
          .then(function (response) {
            if (response.data.status === 200) {
                let new_business_line = response.data.data.map(elm => ({ label: elm.business_line, value: elm.business_line_id, id: elm.business_line_id }));
                if(role_id == Role.ARO && (TENANT=="id" || TENANT=="tri-id")){
                    new_business_line = new_business_line.filter(e=>e.id != "UCF")
                }else if(TENANT == "id" && role_id == Role.PlatinumAgent){
                    new_business_line = new_business_line.filter(e=>(e.id == "UCRF" || e.id == "UBRF"))
                }
                SetBusinessLine(new_business_line);
            }
          })
          .catch(function (response) {
          });
      }

    const getCityAndStateData = async (role_details, setData) => {
        await getCityList(role_details.state, setData, role_details)
        let state_data = stateList.filter(e => role_details.state.includes(e.id))
        if (state_data && state_data.length > 0) {
            setStateData(state_data)
        }
    }

    const getCityList = async (state_ids, setData, role_details) => {
        let temp_role_details = role_details
        if (state_ids.length === 0) {
            temp_role_details["city"] = []
            setCityData([])
            setCityList([])
        } else {
            await MasterService.post('/sfa/user/city_list', { state_ids })
                .then(function (response) {
                    if (response.data.status === 200) {
                        var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                        setCityList(city_list);
                        if (setData) {
                            let city_data = city_list.filter(e => role_details.city.includes(e.id))
                            if (city_data && city_data.length > 0) {
                                let currval = city_data.map(v => v.id);
                                temp_role_details["city"] = currval
                                setCityData(city_data)
                            } else {
                                temp_role_details["city"] = []
                                setCityData([])
                            }
                        }
                    }
                })
                .catch(function (response) {
                });
        }
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(e => e.name !== VIRTUAL_ADMIN)
                    role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    if(adminRoleId === ADMIN_ROLES['sales_support']){
                        role_list = role_list.filter(item => item.id !== parseInt(Role['Agent']))
                    }
                    setRoleList(role_list)
                }
            })
            .catch(function (response) {
            });
    }
    const getUsersByRole = async (role_id, keyname, business_line) => {
        MasterService.post('/sfa/user/get-users', { role_id })
            .then(function (response) {
                business_line = business_line ? business_line : role_details.business_line
                if (response.data.status === 200) {
                    let reporting_manager_list = response.data.data.user_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name, business_line: elm.business_line }));
                    reporting_manager_list = reporting_manager_list.filter(e => e.id != user_detail.user_id)
                    if (keyname === "secondary_reporting_role") {
                        let secondary_reporting_manager_list = [...reporting_manager_list]
                        secondary_reporting_manager_list = secondary_reporting_manager_list.filter(e => e.business_line !== business_line)
                        secondary_reporting_manager_list = secondary_reporting_manager_list.map(item => {
                            return {
                                ...item,
                                label: item.business_line ?  `${item.label} (${item.business_line})` : item.label
                            }
                        })
                        setSecondaryReportingManagerList(secondary_reporting_manager_list)
                    } else if (keyname === "reporting_role") {
                        //reporting_manager_list = reporting_manager_list.filter(e => e.business_line === business_line)
                        reporting_manager_list = reporting_manager_list.map(item => {
                            return {
                                ...item,
                                label: item.business_line ?  `${item.label} (${item.business_line})` : item.label
                            }
                        })
                        setReportingManagerList(reporting_manager_list)
                    }
                }
            })
            .catch(function (response) {
            });
    }
    const getReportingRole = async (temp_role_detail) => {
        let business_line = temp_role_detail.business_line ? temp_role_detail.business_line : ""
        let role_id = temp_role_detail.role ? temp_role_detail.role : ""
        MasterService.post('/sfa/user/reporting_role_list', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setSecondaryReportingRoleList(reporting_role_list)
                    if(business_line == "UCF" && (TENANT=="id" || TENANT=="tri-id")){
                        reporting_role_list = reporting_role_list.filter(e=>e.id != Role.ARO)
                    }
                    if(is_edit){
                        let selected_reporting_role = reporting_role_list.filter(e=>e.id == temp_role_detail.reporting_role)
                        if(selected_reporting_role.length == 0){
                            temp_role_detail.reporting_role = ''
                            temp_role_detail.reporting_manager = ""
                            setRoleDetails(temp_role_detail)
                        }
                    }
                    setReportingRoleList(reporting_role_list)
                }
            })
            .catch(function (response) {
            });
    }
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const handleChangeMultiSel = (field_name, field_obj, event) => {

        let currval = [];
        var role_details_temp = { ...role_details };
        let selected_value = []
        if (event.action === "select-option" && event.option.value === "*") {
            selected_value = field_name === "state" ? [{ label: "Select All", value: "*" }, ...stateList] : [{ label: "Select All", value: "*" }, ...cityList]
            currval = field_name === "state" ? stateList.map(v => v.id) : cityList.map(v => v.id);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            selected_value = []
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            selected_value = field_obj.filter((o) => o.value !== "*")
            currval = selected_value.map(v => v.id)
        } else if (field_name === "state" && field_obj.length === stateList.length) {
            currval = stateList.map(v => v.id);
            selected_value = [{ label: "Select All", value: "*" }, ...stateList]
        } else if (field_name === "city" && field_obj.length === cityList.length) {
            currval = cityList.map(v => v.id);
            selected_value = [{ label: "Select All", value: "*" }, ...cityList]
        } else {
            currval = field_obj.map(v => v.id);
            selected_value = field_obj
        }
        role_details_temp[field_name] = currval;
        if (field_name === "state") {
            setStateData(selected_value);
            getCityList(currval, true, role_details_temp)
        }
        if (field_name === "city") {
            setCityData(selected_value);
        }
        setRoleDetails(role_details_temp);

        let tempErrors = { ...errors };
        if (field_obj.length) {
            tempErrors[field_name] = '';
        }
        SetErrors(tempErrors);
    }

    const handleChange = async (name, selectedOption) => {
        let temp_role_details = { ...role_details };
        let tempErrors = { ...errors };
        if (name === 'role') {
            props.getConfigList(selectedOption.id)
            temp_role_details['reporting_role'] = ""
            temp_role_details['reporting_manager'] = ""
            temp_role_details['secondary_reporting_role'] = ""
            temp_role_details['secondary_reporting_manager'] = ""
            temp_role_details['business_line'] = ""
            temp_role_details[name] = selectedOption.id
            getBusinessLineList(selectedOption.id)
            setRoleChange(true)
        } else if (name === 'reporting_role') {
            temp_role_details['reporting_manager'] = ""
            let tempConfigList = props.ConfigList;
            if (role_details.role === Role['Agent']) {
                if (selectedOption.id === Role['ARO']) {
                    tempConfigList.tagging_roles = tempConfigList?.tagging_roles?.filter(item => ![...ECA_AGENT_TAGGING, 'dsoo'].includes(item.value)) || [];
                    props.setConfigList(tempConfigList);
                } else if (selectedOption.id !== Role['BM']) {
                    tempConfigList.tagging_roles = tempConfigList?.tagging_roles?.filter(item => !ECA_AGENT_TAGGING.includes(item.value)) || [];
                    props.setConfigList(tempConfigList);
                } else {
                    props.getConfigList(role_details.role);
                }
            } else {
                props.getConfigList(role_details.role);
            }
            setReporteeRoleChange(true);
            getUsersByRole(selectedOption.id, name)
            temp_role_details[name] = selectedOption.id
        } else if (name === 'secondary_reporting_role') {
            getUsersByRole(selectedOption.id, name)
            temp_role_details[name] = selectedOption.id
        } else if (name === 'business_line') {
            temp_role_details['reporting_role'] = ""
            temp_role_details['reporting_manager'] = ""
            temp_role_details['secondary_reporting_role'] = ""
            temp_role_details['secondary_reporting_manager'] = ""
            temp_role_details[name] = selectedOption.id
            getReportingRole(temp_role_details)
        } else {
            temp_role_details[name] = selectedOption.id
        }
        setRoleDetails(temp_role_details);
        if(selectedOption.id !=''){
            tempErrors[name]='';
        }
        SetErrors(tempErrors);
    };

    const handleClickCancel = () => {
        if (user_detail?.user_id) {
            historyLead('/view-user/' + user_detail?.user_id_hash, { replace: true })
        }
    }

    const handleResetSecondary = () => {
        setSecondaryReportingManagerList([])
        // setSecondaryReportingRoleList([])
        let tempRoleDetail = {...role_details}
        tempRoleDetail.secondary_reporting_role = ""
        tempRoleDetail.secondary_reporting_manager = 0
        setRoleDetails(tempRoleDetail)
    }

    const handleClickNext = async () => {
        if (isValidate()) {
            let open_lead_and_reportee_data = user_detail && user_detail.open_lead_and_reportee_data ? user_detail.open_lead_and_reportee_data : {}
            if (is_edit && (role_details.business_line !== role_details.previous_business_line || (parseInt(role_details.role) !== parseInt(role_details.previous_role_id)) || (parseInt(role_details.reporting_manager) !== parseInt(role_details.previous_manager_id)))) {
                setLoading(true)
                let params = {
                    user_id: user_detail.user_id,
                    role_id: role_details.previous_role_id
                }
                let ready_to_role_change = false
                let ready_to_role_change_lead = false
                let ready_to_role_change_reportee = false
                let ready_to_role_change_loan = false
                let ready_to_role_change_progression = false;

                let pending_loan_res
                let pending_lead_res

                let wallet_balance = 0
                let user_under_progression = open_lead_and_reportee_data && open_lead_and_reportee_data.progression_users ? open_lead_and_reportee_data.progression_users : []

                if (user_under_progression && user_under_progression.progression_user && user_under_progression.progression_mapping) {
                    if (user_under_progression.progression_user.length === 0 && user_under_progression.progression_mapping.length === 0) {
                        ready_to_role_change_progression = true;
                    } else {
                        // ready_to_role_change_progression = true
                        toast.error("Cannot update Role & Reporting as user is under journey flow")
                    }
                } else {
                    ready_to_role_change_progression = true;
                }
                
                let agent_role_id = (TENANT=="id" || TENANT=="tri-id") ? parseInt(Role.Agent): parseInt(Role.FA)
                let bro_role_id = (TENANT=="id" || TENANT=="tri-id") ? parseInt(Role.BRO): parseInt(Role.MO)
                if(!([agent_role_id, bro_role_id].includes(role_details.role) && [agent_role_id, bro_role_id].includes(role_details.previous_role_id)) || role_details.business_line !== role_details.previous_business_line){
                    // if (parseInt(role_details.role) !== parseInt(VP_ID)) {
                    params.user_id = parseInt(params.user_id);
                    pending_loan_res = open_lead_and_reportee_data && open_lead_and_reportee_data.pendingRefinanceLeads ? open_lead_and_reportee_data.pendingRefinanceLeads : []
                    pending_lead_res = open_lead_and_reportee_data && open_lead_and_reportee_data.pendingLoanboxLeads ? open_lead_and_reportee_data.pendingLoanboxLeads : []
                    // }

                    if ((role_details.business_line !== role_details.previous_business_line || (parseInt(role_details.role) !== parseInt(role_details.previous_role_id)) ||
                        (role_details.previous_secondary_reporting_role_id) ||
                        parseInt(role_details.previous_reporting_role) !== parseInt(role_details.reporting_role))) {
                        if (open_lead_and_reportee_data && open_lead_and_reportee_data.allReporteeList) {
                            let primary_reportee = open_lead_and_reportee_data.allReporteeList.filter(e=>!e.is_secondary)
                            //let secondary_reportee = open_lead_and_reportee_data.allReporteeList.filter(e=>e.is_secondary)
                            if(primary_reportee.length) {
                                toast.error("Please remove the primary reportees")
                            }else {
                                ready_to_role_change_reportee = true
                            }
                        } else {
                            ready_to_role_change_reportee = true
                        }
                    } else {
                        ready_to_role_change_reportee = true
                    }

                    // if (parseInt(role_details.role) !== parseInt(VP_ID)) {
                    let errArr = {
                        ucrf: [],
                        ubrf: [],
                        ucf: []
                    };
                    let errorLoan = false;
                    let errorLead = false;
                    let isCheckedLoan = false;
                    let isCheckedLead = false;
                    if (pending_loan_res && pending_loan_res.data && pending_loan_res.status === 200) {
                        isCheckedLoan = true;
                        for (const key of ['UCRF', 'UBRF']) {
                            if (pending_loan_res && pending_loan_res.data && pending_loan_res.data[key]) {
                                for (const item of pending_loan_res.data[key]) {
                                    for (const [k, v] of Object.entries(item['validation'])) {
                                        if (v === 1) {
                                            errorLoan = true;
                                            errArr[`${key.toLowerCase()}`].push(k);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (pending_lead_res && pending_lead_res.data && pending_lead_res.status === 200) {
                        isCheckedLead = true;
                        if (pending_lead_res && pending_lead_res.data && pending_lead_res.data) {
                            for (const item of pending_lead_res.data) {
                                if (item.is_wallet_amount) {
                                    wallet_balance = item.is_wallet_amount
                                }
                                for (const [k, v] of Object.entries(item['validation'])) {
                                    if (v === 1) {
                                        errorLead = true;
                                        errArr['ucf'].push(k);
                                    }
                                }
                            }
                        }
                    }

                    if (errArr.ucrf.length !== 0 || errArr.ubrf.length !== 0 || errArr.ucf.length !== 0) {
                        for (const [k, v] of Object.entries(errArr)) {
                            if (v.length !== 0) {
                                toast.error(`Error: ${v.join(',')} in ${k.toUpperCase()}`)
                            }
                        }
                    }

                    if ((TENANT =="id" || TENANT =="fav-id") && !errorLoan && isCheckedLoan) {
                        ready_to_role_change_loan = true;
                    }else if(TENANT =="tri-id"){
                        ready_to_role_change_loan = true;
                    }

                    if (!errorLead && isCheckedLead) {
                        ready_to_role_change_lead = true;
                    }

                    if (TENANT=="id" || TENANT=="tri-id") {
                        if (ready_to_role_change_lead && ready_to_role_change_loan && ready_to_role_change_reportee && ready_to_role_change_progression) {
                            ready_to_role_change = true
                        }
                    } else {
                        if (ready_to_role_change_loan && ready_to_role_change_reportee && ready_to_role_change_progression) {
                            ready_to_role_change = true
                        }
                    }
                    // } else {
                    //     if (ready_to_role_change_reportee) {
                    //         ready_to_role_change = true
                    //     }
                    // }
                } else {
                    ready_to_role_change = true;
                }
                //console.log("ready_to_role_change", ready_to_role_change)
                if (role_change) {
                    updateBasicDetail();
                }
                if (TENANT=="fav-id" && parseInt(role_details.role) !== parseInt(role_details.previous_role_id)) {
                    if (wallet_balance && validationForFields.agent_wallet_view.includes(parseInt(params.role_id))) {
                        ready_to_role_change = false
                        toast.error("Please make sure the Wallet balance is Rp 0 !")
                    }
                }
                //console.log("ready_to_role_change", ready_to_role_change)
                if (ready_to_role_change && ready_to_role_change_progression) {
                    handleClick(true)
                }
                setLoading(false)
            } else {
                handleClick(false)
            }
        }
    }

    const handleClick = async (user_logout) => {
        //console.log("res")
        if (is_edit) {
            await props.setUserLogout(user_logout)
        }

        if (reporteeRoleChange) {
            updateEcaAgent({ reporting_role: role_details.reporting_role, role: role_details.role });
        }
        await props.handleSave(role_details, 'role_details', false)
        if (is_edit) {
            // historyLead('basic_details?id=' + user_detail.user_id);
            historyLead("/edit-user/basic_details?id=" + user_detail.user_id_hash);
        } else {
            // historyLead('basic_details');
            historyLead('/add-user/basic_details');
        }
    }

    const isValidate = () => {
        let tempRoleDetail = { ...role_details };
        let formIsValid = true;
        let tempErrors = {};


        if (!tempRoleDetail["state"] || !tempRoleDetail["state"].length) {
            formIsValid = false;
            tempErrors["state"] = 'State is required';
        }
        if (!tempRoleDetail["city"] || !tempRoleDetail["city"].length) {
            formIsValid = false;
            tempErrors["city"] = 'City is required';
        }
        if (!tempRoleDetail["business_line"]) {
            formIsValid = false;
            tempErrors["business_line"] = 'Business Line is required';
        }
        if (!tempRoleDetail["role"]) {
            formIsValid = false;
            tempErrors["role"] = 'Role is required';
        } else {
            if(tempRoleDetail["role"] == Role.ARO && tempRoleDetail["business_line"] == "UCF"){
                formIsValid = false;
                tempErrors["business_line"] = 'Business Line Not Valid';
            }
        }
        if (!tempRoleDetail["reporting_role"]) {
            formIsValid = false;
            tempErrors["reporting_role"] = 'Primary Reporting Role is required';
        }
        if (!tempRoleDetail["reporting_manager"]) {
            formIsValid = false;
            tempErrors["reporting_manager"] = 'Primary Reporting Manager is required';
        } 
        // else {
        //     if(tempRoleDetail["business_line"]){
        //         let selected_manager = reportingManagerList.filter(e=>e.id == tempRoleDetail["reporting_manager"])
        //         if(selected_manager.length && selected_manager[0].business_line != tempRoleDetail["business_line"]){
        //             formIsValid = false;
        //             tempErrors["reporting_manager"] = 'Primary Reporting Manager Not Valid';
        //         }
        //     }
        // }

        // if (tempRoleDetail["secondary_reporting_manager"] && tempRoleDetail["business_line"]) {
        //     let selected_manager = secondaryReportingManagerList.filter(e=>e.id == tempRoleDetail["secondary_reporting_manager"])
        //     if(selected_manager.length && selected_manager[0].business_line == tempRoleDetail["business_line"]){
        //         formIsValid = false;
        //         tempErrors["secondary_reporting_manager"] = 'Secondary Reporting Manager Not Valid';
        //     }
        // }

        // if (validationForFields.secondary_reporting_div.includes(parseInt(role_details.role)) &&
        //     validationForFields.secondary_reporting_div_business_line.includes(role_details.business_line)) {
        //     if (!tempRoleDetail["secondary_reporting_role"]) {
        //         formIsValid = false;
        //         tempErrors["secondary_reporting_role"] = 'Secondary Reporting Role is required';
        //     }
        //     if (!tempRoleDetail["secondary_reporting_manager"]) {
        //         formIsValid = false;
        //         tempErrors["secondary_reporting_manager"] = 'Secondary Reporting Manager is required';
        //     }
        // }
        SetErrors(tempErrors);
        return formIsValid;
    }

    return (
        <div className='role-details-form p-xxl add-user-detail-filed'>
            {loading ? <Loader /> : null}
            <h2>Role Details</h2>
            <h3>
                Business Vertical

            </h3>
            <div className='row'>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator:() => null }}
                            options={Business}
                            placeholder="Business"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Business.filter(e => e.id === defaultBusiness)}
                        />
                    </div>
                </fieldset>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator:() => null }}
                            options={Vertical}
                            placeholder="Vertical"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Vertical.filter(e => e.id === defaultVertical)}
                        />
                    </div>
                </fieldset>
            </div>

            <h3>
                Map Region
            </h3>
            <div className='row'>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator:() => null }}
                            options={Zone}
                            placeholder="Zone"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Zone.filter(e => e.id === role_details.zone_id)}
                        />
                    </div>
                </fieldset>
                <fieldset className="multiselect-dropDown col-md-4">
                    <div className="material">

                        {/* <ReactMultiSelectCheckboxes
                                isMulti
                                options={[{ label: "Select All", value: "*" }, ...stateList]}
                                placeholderButtonLabel="State*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={StateData?.length === stateList?.length ? [{ label: "Select All", value: "*" }, ...stateList] : StateData}
                                onChange={handleChangeMultiSel.bind(this, "state")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            /> */}
                        <ReactMultiSelectCheckboxes
                            options={[{ label: "Select All", value: "*" }, ...stateList]}
                            value={StateData?.length === stateList?.length ? [{ label: "Select All", value: "*" }, ...stateList] : StateData}
                            onChange={handleChangeMultiSel.bind(this, "state")}
                            name="state"
                            placeholder="State*"
                            isDisabled={false}
                            isClearable={true}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                        {(StateData && StateData.length > 0) ? <label data-label="State*" className="form-label date-label"></label> : ''}
                        {
                            (errors && errors.state) ? <span className="error show">{errors.state}</span> : ''
                        }
                    </div>
                </fieldset>
                <fieldset className="multiselect-dropDown col-md-4">
                    <div className="material">
                        {/* <ReactMultiSelectCheckboxes
                                isMulti
                                options={[{ label: "Select All", value: "*" }, ...cityList]}
                                placeholderButtonLabel="City*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={cityList?.length > 0 && CityData?.length === cityList?.length ? [{ label: "Select All", value: "*" }, ...cityList] : CityData}
                                onChange={handleChangeMultiSel.bind(this, "city")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            /> */}

                        <ReactMultiSelectCheckboxes
                            options={[{ label: "Select All", value: "*" }, ...cityList]}
                            value={cityList?.length > 0 && CityData?.length === cityList?.length ? [{ label: "Select All", value: "*" }, ...cityList] : CityData}
                            onChange={handleChangeMultiSel.bind(this, "city")}
                            name="city"
                            placeholder="City*"
                            isDisabled={StateData && StateData.length > 0 ? false : true}
                            isClearable={true}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                        {(CityData && CityData.length > 0) ? <label data-label="City*" className="form-label date-label"></label> : ''}
                        {
                            (errors && errors.city) ? <span className="error show">{errors.city}</span> : ''
                        }
                    </div>
                </fieldset>




            </div>

            <h3>
                    Role Details
                </h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                options={roleList}
                                placeholder="Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={roleList.filter(e => e.id === role_details.role)}
                                onChange={handleChange.bind(this, 'role')}
                            />
                        </div>
                        {
                            (errors && errors.role) ? <span className="error show">{errors.role}</span> : ''
                        }
                    </fieldset>
                    {BusinessLine && BusinessLine.length > 0 &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    options={BusinessLine}
                                    value={BusinessLine.filter((e) => e.id === role_details.business_line)}
                                    onChange={handleChange.bind(null, 'business_line')}
                                    placeholder="Business Line*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.business_line) ? <span className="error show">{errors.business_line}</span> : ''
                            }
                        </fieldset>
                    }
                </div>

                <h3>
                    Reporting Details
                </h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                options={reportingRoleList}
                                placeholder="Primary Reporting Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={reportingRoleList.filter(e => e.id === role_details.reporting_role)}
                                isDisabled={role_details.role && role_details.role !== "" ? false : true}
                                onChange={handleChange.bind(this, 'reporting_role')}
                            />
                        </div>
                        {
                            (errors && errors.reporting_role) ? <span className="error show absolute">{errors.reporting_role}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                options={reportingManagerList}
                                value={reportingManagerList.filter(({ id }) => id == role_details.reporting_manager)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                onChange={handleChange.bind(this, 'reporting_manager')}
                                placeholder="Primary Reporting Manager*"
                                className="react-select"
                                isDisabled={role_details.reporting_role && role_details.reporting_role !== "" ? false : true}
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.reporting_manager) ? <span className="error show absolute">{errors.reporting_manager}</span> : ''
                        }
                    </fieldset>

                </div>

                {(TENANT=="id" || TENANT=="tri-id") && validationForFields.secondary_reporting_div.includes(parseInt(role_details.role)) &&
                    validationForFields.secondary_reporting_div_business_line.includes(role_details.business_line) &&
                    <div className='row'>
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    options={secondaryReportingRoleList}
                                    placeholder="Secondary Reporting Role"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={secondaryReportingRoleList.filter(e => e.id === role_details.secondary_reporting_role)}
                                    isDisabled={role_details.role && role_details.role !== "" ? false : true}
                                    onChange={handleChange.bind(this, 'secondary_reporting_role')}
                                />
                            </div>
                            {
                                (errors && errors.secondary_reporting_role) ? <span className="error show absolute">{errors.secondary_reporting_role}</span> : ''
                            }
                        </fieldset>
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    options={secondaryReportingManagerList}
                                    value={secondaryReportingManagerList.filter(({ id }) => id == role_details.secondary_reporting_manager)}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ id }) => id}
                                    onChange={handleChange.bind(this, 'secondary_reporting_manager')}
                                    placeholder="Secondary Reporting Manager"
                                    className="react-select"
                                    isDisabled={role_details.secondary_reporting_role && role_details.secondary_reporting_role !== "" ? false : true}
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.secondary_reporting_manager) ? <span className="error show absolute">{errors.secondary_reporting_manager}</span> : ''
                            }
                        </fieldset>
                        <button className='btn-line m-xs-s reset-secondary_btn' onClick={handleResetSecondary.bind(this)}>
                            Reset Secondary Manager
                        </button>
                    </div>
                }

            <div className='br-top-flex'>
                {is_edit ? <div className="t-right">
                    <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                        Cancel
                    </button>
                </div> : null}
                <div className="t-right">
                    <button className='btn-primary' onClick={handleClickNext.bind(this)}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RoleDetails;
